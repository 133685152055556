import React, { useEffect, useState } from 'react';
import styles from './billing.module.scss';
import { VaText, VaTitle } from 'shared/components/typography';
import { Button, Progress, Spin, Modal, Card, Col, Row, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getUsageQuota, checkoutPricing } from './redux/billingSlice';
import { loadStripe } from '@stripe/stripe-js';

// Load your Stripe publishable key
const stripePromise = loadStripe(
  'pk_live_51QBrHeCEoVTYWzcpD0ilphRMPyJ65sEzKQdkr2mh6R12Bi9RtWmEXLAHWjiT3z1jK9dbQSAbI30fCb268x8M7X6B00Ij5n6qdo'
);

export default function Billings() {
  const dispatch = useDispatch();
  const [balanceQuota, setBalanceQuota] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [billingList, setBillingList] = useState(null);
  const {
    usageQuota,
    status,
    error,
    billingActivityList,
    billingActivityStatus,
    billingActivityError
  } = useSelector((state) => state.billing);

  useEffect(() => {
    dispatch(getUsageQuota());
  }, [dispatch]);

  useEffect(() => {
    if (status === 'succeeded' && usageQuota) {
      let balance = usageQuota?.data?.balance;
      balance = parseFloat((balance / 60).toFixed(2));
      setBalanceQuota(balance);
      if (balance !== null && balance <= 0) {
        setIsModalVisible(true);
      } else {
        setIsModalVisible(false);
      }
    }
  }, [balanceQuota, status, usageQuota]);

  useEffect(() => {
    if (billingActivityStatus === 'succedded') {
      setBillingList(billingActivityList?.data?.activities);
    }
  }, [billingActivityList, billingActivityStatus]);

  // Handle the OK button or any action in the modal
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const pricingData = [
    {
      // plan: 'Basic',
      minutes: '+ 30',
      payment_plan: 'PAY_10_USD',
      price: 10,
      features: ['Minutes of', 'video Generation']
    },
    {
      // plan: 'Standard',
      minutes: '+ 60',
      payment_plan: 'PAY_20_USD',
      price: 20,
      features: ['Minutes of', 'video Generation']
    },
    {
      // plan: 'Pro',
      minutes: '+ 150',
      payment_plan: 'PAY_50_USD',
      price: 50,
      features: ['Minutes of', 'video Generation']
    },
    {
      // plan: 'Business',
      minutes: '+ 300',
      payment_plan: 'PAY_100_USD',
      price: 100,
      features: ['Minutes of', 'video Generation']
    }
  ];

  const handleBuyNow = async (paymentPlan) => {
    const stripe = await stripePromise;

    // Assume checkoutPricing dispatch will return the clientSecret
    const response = await dispatch(
      checkoutPricing({ payment_plan: paymentPlan })
    );
    console.log(response);
    // Access the clientSecret directly from response.payload.data
    const sessionId = response?.payload?.data?.id;
    console.log(sessionId);
    // Redirect to Stripe Checkout using session.id
    const checkout = await stripe.redirectToCheckout({
      sessionId: sessionId // Use the session ID here
    });
    console.log(checkout);
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'timestamp',
      key: 'timestamp'
    },
    {
      title: 'Quota',
      dataIndex: 'quota',
      key: 'quota'
    }
  ];

  return (
    <div className={styles.wrapper}>
      <div>
        <VaTitle
          level={5}
          text="Billing - Usage Report & Add-on"
          style={{ marginTop: '10px', fontSize: '19px', fontWeight: 'bold' }}
        />
        {status === 'succeeded' ? (
          <div className={styles.card}>
            <div className={styles.header}>
              <VaText text="Your Progress" style={{ fontWeight: 'bold' }} />
              <Button>Free Mode</Button>
            </div>
            <div className={styles.contnet}>
              <Progress
                percent={parseFloat(((balanceQuota / 30) * 100).toFixed(2))}
                status="active"
                strokeColor={{
                  '0%': '#108ee9', // Color at the start
                  '100%': '#87d068' // Color at full progress
                }}
              />
              <VaText
                text={`Balance - ${balanceQuota} minutes`}
                style={{ color: 'darkorange', fontWeight: 'bold' }}
              />
            </div>
            {/* <div className={styles.footer}>
              <Button type="primary">Upgrade</Button>
            </div> */}
            {/* Modal shown when balanceQuota is 0 */}
            <Modal
              title="Upgrade"
              open={isModalVisible}
              onOk={handleOk}
              onCancel={handleOk}
              okText="Ok"
            >
              <p>
                You've hit your video generation limit! Upgrade now to Generate
                Video.
              </p>
            </Modal>
          </div>
        ) : (
          <div style={{ textAlign: 'center', height: '500px' }}>
            <Spin
              size="large"
              className="spinner"
              style={{ display: 'block', margin: '0 auto', marginTop: '200px' }}
            />
            <div style={{ marginTop: '20px' }}>
              <h3>Loading, please wait...</h3>
            </div>
          </div>
        )}
      </div>

      {/* Pricing cards */}
      <div
        className="pricing-card"
        style={{
          backgroundColor: 'white',
          borderRadius: '10px',
          paddingLeft: '60px',
          paddingBottom: '20px'
        }}
      >
        <Row gutter={[16, 16]} justify="center">
          {pricingData.map((item, index) => (
            <Col key={index} xs={24} sm={12} md={8} lg={6}>
              <Card
                className="card-hover"
                // title={item.plan}
                hoverable
                bordered={true}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '300px',
                  height: '100%',
                  maxWidth: '250px',
                  maxHeight: '320px',
                  textAlign: 'center',
                  marginTop: '30px',
                  marginBottom: '20px',
                  borderRadius: '10px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.4)',
                  overflow: 'hidden',
                  marginBottom: '1rem'
                }}
              >
                <div
                  style={{
                    flexGrow: 1,
                    marginTop: '100px',
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    padding: '10px',
                    paddingBottom: '100px'
                  }}
                >
                  <div
                    style={{
                      fontSize: '30px',
                      fontWeight: 'bolder',
                      color: '#FFB000',
                      marginBottom: '0.5rem',
                      position: 'relative',
                      top: '-50px',
                      textAlign: 'center'
                    }}
                  >
                    {item.minutes}
                  </div>
                  <ul style={{ listStyleType: 'none', padding: 0 }}>
                    {item.features.map((feature, i) => (
                      <li
                        key={i}
                        style={{
                          marginBottom: '0.5rem',
                          fontWeight: 'bolder',
                          position: 'relative',
                          top: '-30px'
                        }}
                      >
                        {feature}
                      </li>
                    ))}
                  </ul>

                  <h2
                    style={{
                      fontSize: '2rem',
                      marginBottom: '0.5rem',
                      fontWeight: 'bold'
                    }}
                  >
                    ${item.price}
                  </h2>
                </div>
                <Button
                  className="custom-upgrade-button"
                  type="primary"
                  style={{
                    width: '100%',
                    height: '4.5rem',
                    fontSize: '1.55rem',
                    position: 'absolute',
                    bottom: '0',
                    left: '0',
                    backgroundColor: '#FFA62F',
                    borderColor: '#FFA62F',
                    color: '#ffffff',
                    borderRadius: '4%'
                  }}
                  onClick={() => handleBuyNow(item.payment_plan)}
                >
                  <span style={{ fontWeight: 'bold' }}>Buy Now</span>
                </Button>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
      <div
        className="pricing-card"
        style={{
          backgroundColor: 'white',
          borderRadius: '10px',
          paddingLeft: '60px',
          paddingBottom: '20px'
        }}
      >
        <Table columns={columns} dataSource={billingList} />
      </div>
    </div>
  );
}
