import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Spin, Row, Col, Button, message } from 'antd';
import toast from 'react-hot-toast';

const Preview = ({ selectedShortData }) => {
  const [loading, setLoading] = useState(false);
  const [videoData, setVideoData] = useState(selectedShortData);
  const [aspectRatio, setAspectRatio] = useState('16:9'); // Default aspect ratio

  const handleDownload = (url) => {
    const a = document.createElement('a');
    a.href = url;
    a.download = 'video.mp4';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    toast.success(<>File downloaded successfully</>);
  };
  // Function to handle video metadata load and calculate the aspect ratio
  const handleVideoMetadata = (e) => {
    const { videoWidth, videoHeight } = e.target;
    const ratio = (videoWidth / videoHeight).toFixed(2);

    // Check if the video is in 9:16 (portrait) or 16:9 (landscape) aspect ratio
    if (ratio < 1) {
      setAspectRatio('9:16');
    } else {
      setAspectRatio('16:9');
    }
  };
  return (
    <div
      style={{
        height: '65vh',
        width: '90vw',
        margin: '9vh auto',
        overflow: 'hidden',
        position: 'relative',
        borderRadius: '8px',
        backgroundColor: '#fff'
      }}
    >
      <div
        style={{
          position: 'relative',
          height: '100%',
          width: '100%',
          padding: '20px'
        }}
      >
        <Row
          style={{
            height: '60%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {loading ? (
            <div style={{ textAlign: 'center', width: '100%' }}>
              <Spin
                size="large"
                className="spinner"
                style={{ marginTop: '100px' }}
              />
              <div style={{ marginTop: '20px' }}>
                <h3>Preparing your video for preview, Please wait...</h3>
              </div>
            </div>
          ) : videoData?.video_status === 'VIDEO_UPLOADED' ? (
            <video
              controls
              width={aspectRatio === '9:16' ? '19%' : '60%'}  // Adjust width for 16:9
              height={aspectRatio === '9:16' ? '600px' : 'auto'} // Adjust height for 9:16
              style={{
                margin: 'auto',
                display: 'flex',
                borderRadius: '10px',
                objectFit: 'contain',
                marginTop: '-21px' // Move video slightly to the top
              }}
              onLoadedMetadata={handleVideoMetadata} // Event to trigger when video metadata is loaded
            >
              <source src={videoData.video_location} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <Typography.Title
              level={5}
              className="video-container"
              style={{
                textAlign: 'center',
                fontSize: '20px',
                marginTop: '100px'
              }}
            >
              Video is Not Yet Generated,
              <br />
              Please{' '}
              <b style={{ color: 'orange', fontSize: '25px' }}>Generate</b> a
              Video First.
            </Typography.Title>
          )}
        </Row>
        {/* Download button for video preview */}
        <Row
          style={{
            justifyContent: 'flex-end',
            position: 'fixed',
            top: '20px',
            right: '100px'
          }}
        >
          {!loading && videoData?.video_status === 'VIDEO_UPLOADED' ? (
            <Col>
              <Button
                key="submit"
                type="primary"
                onClick={() => handleDownload(videoData?.video_location)}
              >
                Download Video
              </Button>
            </Col>
          ) : null}
        </Row>
      </div>
    </div>
  );
};

export default Preview;
