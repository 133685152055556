import React from 'react';
import {
  AbsoluteFill,
  spring,
  useCurrentFrame,
  useVideoConfig,
  Img
} from 'remotion';
import {
  Animated,
  Animation,
  Fade,
  Move,
  Scale,
  Size
} from 'remotion-animated';


const TITLE_DURATION = 60;

const TextComponent = ({ textContent }) => {
  return <p>{textContent}</p>;
};

const RemotionTextImageSlide = ({
  title,
  content,
  background,
  animationStyle,
  imageId,
  imageType,
  scene_text_color
}) => {
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();
  var imageFile = '';

    // Set the imageFile based on the imageId and imageType
    if (imageType === 'MP4') {
      // Use the default image for MP4
      imageFile = require('../../../../asset/images/course1.png');
    } else if (imageId !== '' && imageId !== null) {
      // Use the uploaded image if it's not an MP4
    }

  const animations = {
    fadeIn: {
      opacity: spring({ frame, fps, from: 0, to: 1, durationInFrames: 30 })
    },
    slideUp: {
      transform: `translateY(${spring({
        frame,
        fps,
        from: 100,
        to: 0,
        durationInFrames: 30
      })}%)`
    }
    // Add more animations as needed
  };

  return (
    <AbsoluteFill
      className="items-center justify-center"
      style={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%'
      }}
    >
      <Animated
        absolute
        animations={[
          Scale({ by: 1.75, initial: 10 }),
          Fade({ to: 1, initial: 0 })
        ]}
        style={{
          top: '10%'
        }}
      >
        <div style={{ color: scene_text_color.title }} className="text-5xl font-bold leading-relaxed">
          {title}
        </div>
      </Animated>

      <AbsoluteFill
        className="justify-center"
        style={{ width: '50%', top: '5%' }}
      >
        <code style={{ color: scene_text_color.content }} className="text-4xl pl-14 gap-10 grid justify-center">
        {Array.isArray(content) && content.length > 0
          ? content.map((textContent, index) => (
              <TextComponent key={index} textContent={textContent} />
            ))
          : null}
        </code>
      </AbsoluteFill>

      <AbsoluteFill
        className="items-center justify-center"
        style={{
          width: 'calc(50% - 4rem)',
          height: 'calc(100% - 4rem)',
          top: '5%',
          left: '50%',
          margin: '2rem',
          overflow: 'hidden'
        }}
      >
        <Animated
          animations={[
            Fade({ to: 1, initial: 0, start: 0, duration: TITLE_DURATION * 2 })
          ]}
        >
          {/* Render default image if MP4; otherwise, render the uploaded image */}
          {imageType === "MP4" ? (
            <Img src={require('../../../../asset/images/course1.png')} />
          ) : (
            imageId && <Img src={`https://va-sc-images.s3.amazonaws.com/${imageId}`} />
          )}
        </Animated>
      </AbsoluteFill>
    </AbsoluteFill>
  );
};

export default RemotionTextImageSlide;